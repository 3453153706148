<template>
    <div class="users-list">
        <div class="tab-header">
            <div class="tab-switcher">
            </div>
            <div class="tab-add-btn btn" @click="displayModalAdd = true">{{ $t('admin.tab.addPatient') }}</div>
        </div>
        
        <PatientTable 
            :users="patients"
            v-on:updatePatient="updatePatient"
            v-on:deletePatient="deletePatient"
            v-on:updatePathology="updatePathology"
            v-on:updateNotif="updateNotif"
            v-on:updateStade="updateStade"
            v-on:updateArea="updateArea"
            v-on:updateAgreement="updateAgreement"
            v-on:changeWeightOrSize="changeWeightOrSize"
        >
        </PatientTable>
        <ModalAddUser 
            v-if="displayModalAdd"
            v-on:close="displayModalAdd = false"
            v-on:addPatient="addPatient"
            :selectDefault = 1
            :displaySwitch = false
            :selectChoice = [1]
        >
        </ModalAddUser>
    </div>
</template>

<script>
import PatientTable from '../../components/tables/PatientTable'
import ModalAddUser from './../../components/ModalAddUser'
import axios from 'axios'
import store from './../../store'

export default {
    name : 'UsersList',
    components :{
        PatientTable,
        ModalAddUser
    },
    data() {
        return {
            currentSwitch : 1, 
            displayModalAdd : false,       
            patients : [ ],
   
        }
    },
    methods : {
        addPatient(user) {
            this.patients.push(user)
            this.displayModalAdd = false
            this.$store.dispatch('addNotif', {type : 1, content : 1})
        },
        updatePatient() {
            this.$store.dispatch('addNotif', {type : 1, content : 2})
            this.getUsers();
        },
        deletePatient(id) {
            this.patients.splice(this.patients.findIndex(el => el.id === id) ,1)
        },
        updatePathology() {
            this.$store.dispatch('addNotif', {type : 1, content : 13})
            // this.patients[this.patients.findIndex(el => el.id === id)].pathology = pathology;
            this.getUsers()
        },
        updateNotif() {
            this.getUsers();
        },
        updateStade() {
            this.getUsers()
        },
        updateArea() {
            this.getUsers()
        },
        getUsers() {
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .get(store.getters.getURL +"users", config)
                .then(function (response) {
                    vm.patients = response.data
                    vm.patients.sort((a, b) => (a.lastname > b.lastname) ? 1 : -1)
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        },
        updateAgreement() {
            this.getUsers()
        },
        changeWeightOrSize() {
            this.getUsers()
        }


    },
    mounted() {
        this.getUsers()
    }
}
</script>

<style lang="scss" scoped>
    .users-list {
        background: #fff;
        border-radius:10px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        margin:0 auto 80px auto;
        padding: 20px 40px;
        width:$boxWidth;
        max-width: calc(90% - 80px);
        position:relative;
        min-height: calc(100vh - 351px);
    }

</style>